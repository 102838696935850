import React from "react";
import Header from "./Header";
import Subject1 from "./Subject-1";
import Subject2 from "./Subject-2";

import title1Image from "../../resources/department/title1.png";
import title2Image from "../../resources/department/title2.png";

import "./Department.css";

const Department = () => {
  return (
    <div className="text-center w-100">
      <div className="department-container">
        <div className="row text-center">
          <Header image={title1Image} />
        </div>
        <div className="w-100" style={{ height: "10px" }} />

        <Subject1 />
        <div className="w-100" style={{ height: "10px" }} />
        <div className="row text-center">
          <Header image={title2Image} />
        </div>
        <div className="w-100" style={{ height: "10px" }} />
        <Subject2 />
      </div>
    </div>
  );
};

export default Department;
