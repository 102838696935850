import React from "react";
import Breadcrumb from "../components/navbar/Breadcrumb";

import introduce1Image from "../resources/introduce/1.png";
import introduce2Image from "../resources/introduce/2.png";
import introduce3Image from "../resources/introduce/3.png";
import introduce4Image from "../resources/introduce/4.png";
import introduce5Image from "../resources/introduce/5.png";

import introduce6Image from "../resources/introduce/5-5.png";
import introduce7Image from "../resources/introduce/4-1.jpg";
import CenterIntroduceImage from "../resources/introduce/center-introduce.jpg";

const images = [
  introduce1Image,
  introduce2Image,
  introduce3Image,
  introduce4Image,
  introduce5Image,
];

export default class Introduce extends React.Component {
  constructor(props) {
    super(props);
    this.image = images[this.props.match.params.path - 1];
    if (!this.image) {
      this.image = images[0];
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params.path === "4") {
      try {
        var markerPosition = new window.daum.maps.LatLng(35.840521, 128.707468);
        var options = {
          center: new window.daum.maps.LatLng(35.840521, 128.707468),
          level: 4,
        };
        var container = document.getElementById("map");
        var map = new window.daum.maps.Map(container, options);
        var marker = new window.daum.maps.Marker({ position: markerPosition });
        var infowindow = new window.daum.maps.InfoWindow({
          content:
            '<div style="width:150px;text-align:center;font-size: 0.8rem;padding-top:5px;padding-bottom:5px">푸른청신경과(2층)</div>',
        });
        infowindow.open(map, marker);
        marker.setMap(map);
      } catch (error) {
        // no-op
      }
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb
          topMenu="푸른청소개"
          keyName={"introduce"}
          index={this.props.match.params.path - 1}
        />
        <div className="text-center">
          <div className="col-xl-5 d-inline-block">
            <div className="w-100 my-4" style={{ height: "10px" }} />
            {this.props.match.params.path === "4" ? (
              <div id="#map">
                <img className="img-fluid" src={introduce6Image} alt="1" />
                <div
                  id="map"
                  className="col-12 mt-3"
                  style={{ height: "300px" }}
                />
              </div>
            ) : (
              <div />
            )}
            <div>
              <img className="img-fluid" src={this.image} alt="1" />
            </div>
            <div className="w-100 my-4" style={{ height: "10px" }} />
            {this.props.match.params.path === "5" ? 
              (
                <>
                  <img className="img-fluid" src={CenterIntroduceImage} alt="center" />
                  <div className="w-100 my-4" style={{ height: "10px" }} />
                </>
              ) : (
                <div />
              )
            }

            {this.props.match.params.path === "4" ? 
              (
                <>
                  <img className="img-fluid" src={introduce7Image} alt="center" />
                  <div className="w-100 my-4" style={{ height: "10px" }} />
                </>
              ) : (
                <div />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}
